<template>
  <div class="content">
    <div class="content__title content__title--party">
      <h1 class="title title--big title--theme">Список рекламы</h1>
    </div>
    <div class="content__title">
      <router-link :to="{ name: 'commercial-create' }" class="link">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="9" cy="9" r="9" fill="#2B93E7"/>
          <path
            d="M11.9694 9.67347H9.67347V11.9694C9.67347 12.2219 9.46684 12.4286 9.21429 12.4286C8.96173 12.4286 8.7551 12.2219 8.7551 11.9694V9.67347H6.45918C6.20663 9.67347 6 9.46684 6 9.21429C6 8.96173 6.20663 8.7551 6.45918 8.7551H8.7551V6.45918C8.7551 6.20663 8.96173 6 9.21429 6C9.46684 6 9.67347 6.20663 9.67347 6.45918V8.7551H11.9694C12.2219 8.7551 12.4286 8.96173 12.4286 9.21429C12.4286 9.46684 12.2219 9.67347 11.9694 9.67347Z"
            fill="white"/>
        </svg>
        <span>Добавить рекламу</span>
      </router-link>
    </div>
    <v-client-table
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="userListTable"
      class="table-default">
      <div slot="image" slot-scope="props">
        <template v-if="!props.row.image">
          <label class="table-default__button">
            <input type="file" @change="onUploadImage($event, props.row.id)" accept="image/png, image/jpeg">
            Загрузить
          </label>
        </template>
        <template v-else>
          <div class="table-default__buttons">
            <a :href="props.row.image" target="_blank" class="table-default__button">Посмотреть</a>
            <button
              @click="onDeleteImage(props.row.id)"
              class="table-default__button table-default__button--red"
            >
              Удалить
            </button>
          </div>
        </template>
      </div>
      <div slot="is_active" slot-scope="props" :class="{ 'table-default__error': !props.row.is_active }">
        {{ props.row.is_active ? 'Активна' : 'Неактивна' }}
      </div>
      <div slot="tooltip" slot-scope="props">
        <v-popover placement="left-start"
        >
          <button class="tooltip-target b3">
            <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 4C10.1046 4 11 3.10457 11 2C11 0.89543 10.1046 0 9 0C7.89543 0 7 0.89543 7 2C7 3.10457 7.89543 4 9 4Z" fill="#2B93E7"/>
              <path d="M16 4C17.1046 4 18 3.10457 18 2C18 0.89543 17.1046 0 16 0C14.8954 0 14 0.89543 14 2C14 3.10457 14.8954 4 16 4Z" fill="#2B93E7"/>
              <path d="M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z" fill="#2B93E7"/>
            </svg>
          </button>
          <template slot="popover">
            <ul class="tooltip__list">
              <li class="tooltip__item">
                <img src="@/assets/img/edit-icon.svg" alt="Edit icon">
                <router-link :to="{ name: 'commercial-detail', params: { id: props.row.id } }" class="tooltip__btn">Редактировать</router-link>
              </li>
              <li class="tooltip__item">
                <img src="@/assets/img/trash-icon.svg" alt="Delete icon">
                <button @click="deleteItem(props.row.id)" class="tooltip__btn">Удалить</button>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>
    </v-client-table>
  </div>
</template>

<script>

export default {
  name: 'CommercialList',
  data() {
    return {
      table: {
        items: [],
        columns: ['header', 'image', 'sequence_number', 'is_active', 'tooltip'],
        options: {
          headings: {
            header: 'Заголовок',
            image: 'Изображение',
            sequence_number: 'Сортировка',
            is_active: 'Активность',
            tooltip: ''
          },
          pagination: { show: false },
          sortable: [],
          perPage: 100,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Нет подходящих записей',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback: () => 'table-default__row',
        },
      },
      imageFile: '',
    }
  },
  created() {
    this.fetchCommercial()
  },
  methods: {
    onUploadImage(e, id) {
      const data = new FormData()
      data.append('image', e.target.files[0])
      this.$store.dispatch('commercial/PUT_IMAGE', { id, data })
        .then(() => {
          this.fetchCommercial()
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Изображение добавлено'
          })
        })
    },
    onDeleteImage(id) {
      this.$store.dispatch('commercial/DELETE_IMAGE', id)
        .then(() => {
          this.fetchCommercial()
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Изображение удалено'
          })
        })
    },
    fetchCommercial() {
      this.$store.dispatch('commercial/GET_LIST')
        .then(response => { this.table.items = response.data })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
    deleteItem(id) {
      this.$store.dispatch('commercial/DELETE_DATA', id)
        .then(() => {
          document.body.click()
          this.fetchCommercial()
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Реклама удалена'
          })
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          })
        })
    },
  }
}
</script>
